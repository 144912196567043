export function setGlobal(obj) {
    const AE = window.AngularEcommerce = window.AngularEcommerce || {};

    AE.web = AE.web || {};
    AE.web = {
        ...AE.web,
        ...obj
    }
}

export function getGlobal(prop) {
    const AE = window.AngularEcommerce = window.AngularEcommerce || {};
    AE.web = AE.web || {};

    if(typeof prop === 'string') {
        return AE.web[prop];
    }

    return AE.web;
}