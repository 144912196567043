import { init } from './component-loader';

init();

// eslint-disable-next-line no-undef
__ANGULARE_CSS_DEPS__.forEach(url => {
    const link = document.createElement('link');

    document.head.appendChild(link);

    link.href = url;
    link.rel = 'stylesheet';
});

function load(libs) {
    const group = libs.shift();

    const promises = group.map(lib => {
        return new Promise(resolve => {
            const script = document.createElement('script');

            script.onload = resolve;

            document.head.appendChild(script);
            script.src = lib;
        });
    });

    Promise.all(promises)
        .then(() => {
            if (libs.length) {
                load(libs);
            }
        })
}

// eslint-disable-next-line no-undef
load(__ANGULARE_JS_DEPS__);
