import { setGlobal, getGlobal } from '@/common/functions/global.js';

function getState() {
    let state = getGlobal('componentLoaderState');

    if (state) {
        return state;
    }

    setGlobal({
        componentLoaderState: {
            componentsLoaded: [],
            queue: [],
            hasBegan: false
        }
    });

    return getGlobal('componentLoaderState');

}

function setState(obj) {
    const state = getGlobal('componentLoaderState');

    setGlobal({
        componentLoaderState: {
            ...state,
            ...obj,
        }
    });
}

const AE_PREFIX = /^(ae-)/g;

function _loadComponent(tagName) {
    const state = getState();

    const has = state.componentsLoaded.some(item => item === tagName);

    if (has) {
        return;
    }

    const componentName = tagName.replace(AE_PREFIX, '');

    const script = document.createElement('script');
    document.head.appendChild(script);
    // eslint-disable-next-line no-undef
    script.src = `${__ANGULARE_PUBLIC_PATH__}${componentName}.js`;

    // eslint-disable-next-line no-undef
    if (__IS_PROD__) {
        const link = document.createElement('link');
        document.head.appendChild(link);
        // eslint-disable-next-line no-undef
        link.href = `${__ANGULARE_PUBLIC_PATH__}${componentName}.css`;
        link.rel = 'stylesheet';
    }

    setState({
        componentsLoaded: [...state.componentsLoaded, tagName]
    });
}

export function init() {
    const _ = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (!mutation.addedNodes) {
                return;
            }

            for (var i = 0; i < mutation.addedNodes.length; i++) {

                const node = mutation.addedNodes[i]

                const tagName = node.nodeName.toLowerCase();

                if (AE_PREFIX.test(tagName)) {
                    const state = getState();

                    if (state.hasBegan) {
                        _loadComponent(tagName);
                    } else {
                        setState({
                            queue: [...state.queue, tagName]
                        })
                    }
                }
            }
        })
    });

    _.observe(document, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
    });
}

export function begin() {

    const state = getState();

    state.queue.forEach(tagName => {
        _loadComponent(tagName);
    })

    setState({
        hasBegan: true,
        queue: []
    });
}
